<template>
  <div class="node_description_row">
    <!-- 可以折叠的,在抽屉,节点详情页使用 -->
    <collapse v-if="showCollapse" :title="$t('nodeDetail.description.title')">
      <!-- 编辑描述 -->
      <div v-if="is_edit">
        <el-row>
          <zdheditor
            style="flex: 1"
            :key="$route.fullPath"
            :isComment="true"
            :isEdit="true"
            :commentData="description_content"
            :commentId="nodeKey"
          ></zdheditor>
          <!-- <editor
            v-model="description_content"
            :init="editor_init_setting"
            model-events="change keydown blur focus paste focusout"
            @focusOut="save_handler"
          /> -->
        </el-row>
        <!-- <div class="footer_btn">
          <el-button type="info" size="mini" @click="cancel_handler"
            >{{ $t("btn.cancelBtn") }}
          </el-button>
          <el-button
            type="primary"
            size="mini"
            :disabled="imgLoading"
            @click="save_handler"
            >{{ $t("btn.saveBtn") }}
          </el-button>
        </div> -->
      </div>
      <!-- 描述 -->
      <div
        v-else
        v-limits-of-authority="'NODE_EDIT'"
        class="desc"
        :class="{ disable_edit: lock }"
        @click="lock ? add_highlight() : changeEdit($event)"
      >
        <div v-if="!description_content && !lock" class="tip">
          {{ $t("nodeDetail.description.placeholder") }}
        </div>

        <zdheditor
          v-else
          style="flex: 1"
          :key="$route.fullPath"
          :isComment="true"
          :isEdit="false"
          :commentId="nodeKey"
          :commentData="description_content"
        ></zdheditor>

        <!-- <div
         
          class="desc_content"
          :class="{ des_scroll: show_scroll }"
          v-html="description_content"
        ></div> -->
      </div>

      <!-- 附件 -->
      <el-row v-if="show_file" class="upload-detail">
        <!-- 上传 -->
        <el-upload
          v-if="!lock"
          v-limits-of-authority="'NODE_EDIT'"
          drag
          action="#"
          multiple
          :http-request="upload_attachment_handler"
          class="downloadable"
          :on-remove="remove_attachment_handler"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ $t("nodeDetail.description.info1")
            }}<em>{{ $t("nodeDetail.description.info2") }}</em>
          </div>
        </el-upload>

        <!-- 文件列表 -->
        <file-list
          ref="fileList"
          :files="attachments_list || []"
          :project-id="projectId"
          :node-key="nodeKey"
          :deletebtn="!lock"
        ></file-list>
      </el-row>
    </collapse>
    <!-- 不可以折叠的,在word视图下,节点详情页的变更请求处使用 -->
    <div v-else>
      <div v-if="is_edit">
        <el-row>
          <zdheditor
            style="flex: 1"
            :key="$route.fullPath"
            :isComment="true"
            :commentId="nodeKey"
            :isEdit="true"
            :commentData="description_content"
          ></zdheditor>

          <!-- <editor
            v-model="description_content"
            :init="editor_init_setting"
            model-events="change keydown blur focus paste focusout"
            @focusOut="save_handler"
          /> -->
        </el-row>
        <!-- <div class="footer_btn">
          <el-button type="info" size="mini" @click="cancel_handler"
            >{{ $t("btn.cancelBtn") }}
          </el-button>
          <el-button
            type="primary"
            size="mini"
            :disabled="imgLoading"
            @click="save_handler"
            >{{ $t("btn.saveBtn") }}
          </el-button>
        </div> -->
      </div>
      <div
        v-else
        v-limits-of-authority="'NODE_EDIT'"
        class="desc"
        :class="{ disable_edit: lock }"
        @click="lock ? '' : changeEdit($event)"
      >
        <div v-if="!description_content && !lock" class="tip">
          {{ $t("nodeDetail.description.placeholder") }}
        </div>
        <zdheditor
          style="flex: 1"
          v-else
          :key="$route.fullPath"
          :isComment="true"
          :isEdit="false"
          :commentData="description_content"
          :commentId="nodeKey"
        ></zdheditor>
        <!-- 
        <div
          
          class="desc_content"
          :class="{ des_scroll: show_scroll }"
          v-html="description_content"
        ></div> -->
      </div>
      <el-row v-if="show_file" class="upload-attachment">
        <el-upload
          v-if="!lock"
          v-limits-of-authority="'NODE_EDIT'"
          width="100%"
          drag
          action="#"
          multiple
          :http-request="upload_attachment_handler"
          :file-list="attachments_list || []"
          :on-remove="remove_attachment_handler"
        >
          <el-button
            type="primary"
            class="up_btn"
            icon="el-icon-upload2"
            plain
            >{{ $t("btn.uploadBtn") }}</el-button
          >
          <!-- <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ $t("nodeDetail.description.info1")
            }}<em>{{ $t("nodeDetail.description.info2") }}</em>
          </div> -->
        </el-upload>
        <file-list
          ref="fileList"
          :files="attachments_list || []"
          :project-id="projectId"
          :node-key="nodeKey"
          :deletebtn="!lock"
          :change_requests="change_requests"
        ></file-list>
      </el-row>
    </div>

    <el-img-viewer
      v-if="isViewerVisible"
      class="viewer"
      :url-list="nowImgsrc"
      :on-close="closeImgViewer"
    ></el-img-viewer>
  </div>
</template>

<script>
import {
  upload_file,
  get_node_use_node_key,
  only_set_property,
  get_authorization,
} from "@/network/node/index.js";
import zdheditor from "@/views/tiptap";
import { ossAuthorization } from "@/utils/oss";
import Collapse from "./Collapse.vue";
import tinymce from "tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from "@tinymce/tinymce-vue";
import FileList from "@/components/mindmap/node_detail/FileList";
import vmson from "@/utils/vmson";
import "@/i18n/tinymceI18n/zh-Hans.js";
// 插件
import "tinymce/plugins/advlist";
import "tinymce/plugins/image";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
// import "tinymce/plugins/image";
import "tinymce/plugins/preview";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/help";
import "tinymce/plugins/wordcount";
// import "tinymce/plugins/save";
import "tinymce/plugins/autosave";
import "tinymce/plugins/paste";
import "tinymce/plugins/autoresize";
import { ulid } from "ulid";
export default {
  components: {
    Collapse,
    zdheditor,
    editor: Editor,
    FileList,
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    description: {
      type: String,
      default() {
        return this.$t("placeholder.null");
      },
    },
    projectId: {
      type: String,
      default: "",
    },
    nodeKey: {
      type: String,
      default: "",
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    showCollapse: {
      type: Boolean,
      default: true,
    },
    default: {
      type: Boolean,
      default: true,
    },
    change_requests: {
      type: Boolean,
      default: false,
    },
    lock: {
      type: Boolean,
      default: false,
    },
    show_file: {
      type: Boolean,
      default: true,
    },
    show_scroll: {
      type: Boolean,
      default: false,
    },
    lineId: {
      type: String,
      default: "",
    },
  },
  data() {
    let that = this;
    return {
      imgLoading: false,
      editor_init_setting: {
        // language_url: this.$t("editor.languageUrl"),
        language: this.$t("editor.paragraph"),
        skin_url: "/tinymce/skins/ui/oxide",
        relative_urls: false,
        body_class: "edit_body_des",
        content_style:
          ".edit_body_des {  max-height: 200px; overflow-y: scroll !important} img { max-width: 100% !important}",
        menubar: false, // 禁用菜单栏
        resize: true,
        autoresize_bottom_margin: 20,
        autoresize_min_height: 350,
        // autoresize_on_init: true,
        // save_onsavecallback: that.save_handler,
        file_picker_callback: that.upload_file_handler,
        file_picker_types: "file image",
        inline: false,
        paste_data_images: true,
        branding: false,
        images_upload_handler: async function (blobInfo, succFun, failFun) {
          that.imgLoading = true;
          await ossAuthorization.call(that);
          const blob = blobInfo.blob();
          const file = new File([blob], blob.name, {
            type: blob.type,
          });
          that.client
            .put(
              `${that.$store.state.project.tenantId}/node_accessors/${ulid()}${
                file.name
              }`,
              file
            )
            .then((ress) => {
              let json = { location: ress.url };
              succFun(json.location);
              that.imgLoading = false;
            })
            .error((r) => {
              succFun("错误");
              failFun(that.$t("homeTopBar.newProjectForm.message.uploadError"));
              that.imgLoading = false;
            });
        },
        paste_preprocess: function (plugin, args) {
          const images = args.content.match(/<img[^>]*>/g);
          const table = args.content.match(/<table[^>]*>/g);
          if (images) {
            for (let i = 0; i < images.length; i++) {
              const img = document.createElement("img");
              const id = ulid();
              img.id = id;
              img.setAttribute("src", images[i].match(/src="([^"]*)/)[1]);
              img.onload = function () {
                if (img.width >= 525) {
                  document
                    .querySelector(".tox-editor-container iframe")
                    .contentWindow.document.getElementById(id)
                    .setAttribute("width", "525");
                }
              };
              args.content = args.content.replace(images[i], img.outerHTML);
            }
          }
          if (table) {
            // 解决复制表格导出超长问题;
            const div = document.createElement("div");
            div.innerHTML = args.content;
            const tables = div.querySelectorAll("table");
            tables.forEach((tableDom) => {
              tableDom.style =
                "border-collapse: collapse; width: 100%; word-break:break-all";
              const tdsNum = tableDom.querySelector("tbody tr").children.length;
              const tds = tableDom.querySelectorAll("td");
              tds.forEach((tdDom) => {
                // tdDom.setAttribute("width", (1 / tdsNum) * 100 + "%");
              });
            });
            args.content = div.innerHTML;
          }
        },
        plugins:
          "fullscreen autoresize advlist autolink lists link image preview code \
            insertdatetime media table paste help wordcount table autosave",
        toolbar:
          " undo redo | fullscreen | formatselect | fontsizeselect bold italic strikethrough backcolor | \
             link image | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | \
             table tabledelete | tableprops tablerowprops tablecellprops| \
             help",
        default_link_target: "_blank",
        link_context_toolbar: true,
      },
      description_content: "",
      is_edit: false,
      upload_list: [],
      // 图片预览相关
      isViewerVisible: false,
      nowImgsrc: [],
      attachments_list: [],
    };
  },
  watch: {
    description(newValue, oldValue) {
      if (!this.startsWithHtmlTag(this.description)) {
        this.description_content = this.description.replace(/\n/g, "<br>");
      } else {
        this.description_content = this.description;
      }
    },
    attachments(newval) {
      this.attachments_list = newval;
    },
  },
  mounted() {
    console.log(this.nodeKey);

    this.attachments_list = this.attachments;
    if (!this.startsWithHtmlTag(this.description)) {
      this.description_content = this.description.replace(/\n/g, "<br>");
    } else {
      this.description_content = this.description;
    }
    tinymce.init({});
    vmson.$on("update_change_requests_attachments_list", (val) => {
      this.attachments_list = val;
    });

    vmson.$on("export_comments", (val) => {
      console.log(val);
      
      if (val.val == this.nodeKey) {
        this.description_content = val.data;
        this.save_handler();
      }
    });
    vmson.$on("blur_comments", (val) => {
      console.log(val,val == this.nodeKey);
      
      if (val == this.nodeKey) {
        console.log(val);
        this.cancel_handler();
      }
    });

    vmson.$on("update_enclosure_table", (val) => {
      this.attachments_list = val;
      // get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
      //   this.node = res;
      //   this.attachments = res.attachments;
      //   this.upload_list = [];
      // });
    });
  },
  beforeDestroy() {
    vmson.$off("blur_comments");

    vmson.$off("export_comments");
  },
  methods: {
    startsWithHtmlTag(s) {
      return /^<[^>]+>/.test(s);
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    changeEdit($event) {
      if ($event.target.nodeName === "A") {
        return;
      } else if ($event.target.nodeName === "IMG") {
        this.nowImgsrc = [$event.target.currentSrc];
        this.isViewerVisible = true;
        return;
      } else {
        this.is_edit = true;
        if (this.$route.name === "lineword") {
          setTimeout(() => {
            const dom = document.getElementById(this.lineId);
            dom.scrollIntoView({ behavior: "smooth", block: "center" });
          });
        }
      }
    },
    // 添加高亮效果
    add_highlight() {
      this.$message({
        type: "warning",
        message: this.$t("nodeDetail.message.error"),
      });
      let highlight = document.querySelectorAll(".highlight_cascader");
      for (let i = 0; i < highlight.length; i++) {
        highlight[i].firstChild.classList.remove("high_light_cas");
        setTimeout(() => {
          highlight[i].firstChild.classList.add("high_light_cas");
        });
      }
    },
    cancel_handler() {
      console.log('89897');
      
      get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
        if (!this.startsWithHtmlTag(res.description)) {
          this.description_content = res.description.replace(/\n/g, "<br>");
        } else {
          this.description_content = res.description;
        }
        this.is_edit = false;
      });
    },
    save_handler() {
      this.$emit("update_description_content", this.description_content);
      if (this.default) {
        const params = {
          projectId: this.get_pid(),
          nodeKey: this.nodeKey,
          data: {
            isCustom: false,
            fieldId: "description",
            value: this.description_content,
          },
        };
        only_set_property(params).then((res) => {
          this.is_edit = false;
          this.$message.success(this.$t("chart.message.saveSuccess"));
        });
      } else {
        this.is_edit = false;
        this.$emit("sendvalue", this.description_content);
        this.$message.success(this.$t("chart.message.saveSuccess"));
      }
    },
    upload_file_handler(callback, value, meta) {
      let filetype =
        ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4"; //限制文件的上传类型
      let inputElem = document.createElement("input"); //创建文件选择
      inputElem.setAttribute("type", "file");
      inputElem.setAttribute("accept", filetype);
      inputElem.click();
      inputElem.onchange = async () => {
        let file = inputElem.files[0]; //获取文件信息
        let params = new FormData();
        params.append("attachment", file);
        upload_file(params)
          .then((res) => {
            const arr = res.split("/");
            callback(res, { text: arr[arr.length - 1] });
            callback(res);
          })
          .catch(() => {
            alert(this.$t("homeTopBar.newProjectForm.message.uploadError2"));
          });
      };
    },
    async upload_attachment_handler(event) {
      const isLt100M = event.file.size / 1024 / 1024 > 100;
      if (isLt100M) {
        this.$message({
          type: "warning",
          message: this.$t("homeTopBar.newFileForm.limit2"),
        });
        return;
      }
      this.upload_list.push(event);
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(async () => {
        const loading = this.$loading({
          lock: true,
          text: this.$t("loading.loading5"),
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        await ossAuthorization.call(this);
        let nodedata = [];
        if (!this.change_requests) {
          nodedata = await get_node_use_node_key(this.get_pid(), this.nodeKey);
        } else {
          nodedata = {
            attachments: this.attachments,
          };
        }
        const promiseList = [];
        await this.upload_list.forEach(async (item) => {
          // promiseList.push(this.client.put("/node_accessors/" + Date.now() + item.file.name, item.file))
          promiseList.push(
            this.client.put(
              `/${
                this.$store.state.project.tenantId
              }/node_accessors/${Date.now()}${item.file.name}`,
              item.file
            )
          );
        });
        Promise.all(promiseList).then((reslist) => {
          reslist.forEach((ress, index) => {
            let d = {
              name: this.upload_list[index].file.name,
              url:
                "/" +
                this.$store.state.project.tenantId +
                "/node_accessors/" +
                ress.url.split("/node_accessors/")[1],
            };
            nodedata.attachments.push(d);
          });

          this.attachments_list = nodedata.attachments;
          this.upload_list = [];
          if (!this.change_requests) {
            const params = {
              projectId: this.get_pid(),
              nodeKey: nodedata.key,
              data: {
                isCustom: false,
                fieldId: "attachments",
                value: nodedata.attachments,
              },
            };
            only_set_property(params).then((res) => {
              this.node = nodedata;
              loading.close();
            });
          } else {
            this.$emit("sendfile", this.attachments_list);
            loading.close();
          }
        });
      }, 500);
    },
    remove_attachment_handler(file, fileList) {},
  },
};
</script>

<style scoped>
/deep/ .el-upload-list {
  display: none;
}
</style>
<style lang="scss" scoped>
.node_description_row {
  background-color: #fff;
  padding: 0 10px; /*no*/
  margin-left: 0;
  padding-top: 10px;
  border-radius: 4px;
  .desc {
    margin: 10px 0;

    cursor: pointer;
    overflow-x: auto;
    border: 1px solid white;
    border-radius: 4px;
    &:hover {
      // margin-top: -10px;
      // margin-bottom: 0px;
      // padding: 10px;
      // padding-left: 0;
      // background-color: rgba(229, 229, 229, 1);
      border: 1px dashed #409EFF;
    }
  }

  .footer_btn {
    margin: 10px 0;
  }
}
.tip {
  text-align: left;
  color: #a6a6a6;
}

.upload-detail {
  ::v-deep .el-upload {
    width: 100%;
    height: 80px;

    .el-upload-dragger {
      width: 100%;
    }
  }
}

.upload-attachment {
  .up_btn {
    background-color: white;
    border: 1px solid #a6a6a6;
    color: #a6a6a6;
  }
  margin-top: 12px;

  ::v-deep .el-upload {
    //   width: 100%;
    //   height: 80px;

    .el-upload-dragger {
      padding: 10px;
      border: none;
      border-radius: 0;
      width: auto;
      height: auto;
    }
  }
}

.des_scroll {
  height: 346px; /*no*/
  overflow-y: scroll;
  white-space: normal;
}

.disable_edit {
  cursor: not-allowed !important;
}
.desc_content {
  word-break: break-all;
  max-width: 100%;
  white-space: normal;
  text-align: left;
}
</style>
<style lang="scss">
.desc_content {
  img {
    max-width: 100%;
  }
}
</style>
