<template>
  <div
    class="baseline_before_start"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <!-- 编辑状态 -->
    <div v-show="is_edit" class="header">
      <div class="header__baseline-select">
        <el-input
          v-model="baseline_name"
          size="small"
          @blur="change_name"
        ></el-input>
      </div>
      <div class="header__btns">
        <div class="save_tip">
          <i class="iconfont icon-refresh" :class="{ svg_animation: saving }">
          </i>
          {{ $t("baseline.topBar.autoSave") }}
        </div>
        <div>
          <baseline-deletebtn
            v-limits-of-authority="'DATUM_LINE_DELETE'"
            @edit="edit"
            @change_baseline="change_baseline"
          ></baseline-deletebtn>
        </div>
        <el-button type="info" size="small" @click="exit_edit"
          ><i class="iconfont icon-exit" style="margin-right: 5px"></i>
          {{ $t("btn.exitEditBtn") }}</el-button
        >
      </div>
    </div>
    <!-- 非编辑 -->
    <div v-show="!is_edit" class="header">
      <div class="header__baseline-select">
        <baseline-select
          v-if="show_baseline_select"
          :isDataLoaded="isDataLoaded"
          v-model="select_data"
          @change_baseline="change_baseline"
        ></baseline-select>
        <div>
          <div
            v-limits-of-authority="'DATUM_LINE_START_END'"
            class="play"
            @click="to_play"
          >
            <!-- <i
              class="iconfont icon-play"
              style="color: rgb(48, 100, 143); font-size: 25px"
            ></i> -->
            <button class="startBtn">
              <i
                class="iconfont icon-play"
                style="color: rgba(255, 255, 255); font-size: 21px"
              ></i>
              <span class="startText">{{ $t("btn.startBtn") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="header__btns">
        <baseline-copybtn
          v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'"
        ></baseline-copybtn>
        <div v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'">
          <el-button
            type="primary"
            :disabled="
              limits_of_authority_disable('DATUM_LINE_CREATE_EDIT_COPY')
            "
            size="small"
            @click="edit(true)"
            ><i class="iconfont icon-branch_edit" style="margin-right: 5px"></i>
            {{ $t("btn.editBtn") }}</el-button
          >
        </div>
      </div>
    </div>

    <div
      style="position: relative"
      @mouseenter="summaryOnmouserEnter"
      @mouseleave="summaryOnmouserLeave"
    >
      <el-collapse
        v-model="activeNames"
        class="scroll_wrap"
        @change="handleChange"
      >
        <el-collapse-item class="collapse_item" name="1">
          <div slot="title" class="collapse-title">
            {{ $t(`nodeDetail.basicInfo.title`) }}
          </div>
          <div class="baseline-info">
            <div class="create-time-and-person">
              <el-row style="width: 100%">
                <el-col :span="8">
                  <div class="create-time">
                    <div>
                      <span
                        class="title"
                        style="
                          min-width: 120px;
                          display: inline-block;
                          text-align: left;
                        "
                        >{{ $t("baseline.basicInfo2.startTime") }}:
                      </span>
                      {{ baseline_info.createdAt }}
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="create_name">
                    <span class="title"
                      >{{ $t("baseline.basicInfo2.startPeople") }}:
                    </span>
                    {{ baseline_info.createByName }}
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <span class="title"
                      >{{ $t("baseline.basicInfo.baselineNotice") }}:
                    </span>
                    <span v-show="!is_edit">
                      <span>
                        <span
                          v-for="(item, index) in get_show_person()"
                          :key="index"
                          >{{ item }}，</span
                        ></span
                      >
                    </span>
                    <el-select
                      v-show="is_edit"
                      v-model="baseline_notice"
                      multiple
                      :placeholder="$t('placeholder.dropdown')"
                      size="small"
                      filterable
                      @visible-change="save_notice_person"
                      @remove-tag="save_notice_person"
                    >
                      <el-option-group
                        v-for="(group, index) in groupandUser"
                        :key="group.label"
                        :label="group.label"
                      >
                        <el-option
                          v-for="user in group.options"
                          :key="user.value"
                          :label="user.label"
                          :value="user.value + index"
                        >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="is_edit" class="baseline-description">
              <span
                class="title"
                style="
                  min-width: 120px;
                  display: inline-block;
                  text-align: left;
                "
                >{{ $t("baseline.basicInfo.baselineDesc") }}:
              </span>
              <el-input
                v-model="baseline_desc"
                type="textarea"
                :autosize="{ maxRows: 4 }"
                @blur="change_desc"
              ></el-input>
            </div>
            <div v-else class="baseline-description">
              <span
                class="title_show"
                style="
                  min-width: 120px;
                  display: inline-block;
                  text-align: left;
                "
                >{{ $t("baseline.basicInfo.baselineDesc") }}:
              </span>
              <span class="plan-text">
                <el-scrollbar style="height: 100%">
                  <span v-html="newDescription"></span> </el-scrollbar
              ></span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- 添加节点下拉框 -->
      <baseline-addnode
        v-if="is_edit && show_addnode"
        @reload="reload"
        style="position: absolute; right: 20px; bottom: -34px"
      ></baseline-addnode>
    </div>
    <!-- 节点表格 -->
    <baseline-before-start-table
      v-if="show_table"
    ></baseline-before-start-table>
  </div>
</template>

<script>
import { limits_of_authority_disable } from "@/utils/limits";
import BaselineBeforeStartTable from "./BaselineBeforeStartTable";
import BaselineSelect from "@/components/release/components/BaselineSelect";
import BaselineCopybtn from "./BaselineCopybtn";
import BaselineDeletebtn from "./BaselineDeletebtn";
import BaselineAddnode from "./BaselineAddnode";
import {
  get_data,
  start_baseline,
  change_baseinfo,
  change_baseline_name,
} from "@/network/baseline/index";
import { get_allProjectMember } from "@/network/node/index.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BaseLine",
  components: {
    BaselineBeforeStartTable,
    BaselineSelect,
    BaselineCopybtn,
    BaselineDeletebtn,
    BaselineAddnode,
  },
  props: ["dataLoaded"],
  data() {
    return {
      baseline_info: {},
      baseline_name: "",
      baseline_desc: "",
      baseline_notice: [],
      select_data: {
        baselineId: "",
        name: "",
        status: "",
      },
      saving: false,
      show_table: true,
      show_baseline_select: true,
      show_addnode: true,

      // 添加基线弹窗
      add_baseline_dialog: false,
      add_baseline_form: {
        name: "",
        desc: "",
      },
      add_baseline_rules: {},
      //复制基线弹窗
      copy_baseline_dialog: false,
      copy_name: "",
      newDescription: "",
      activeNames: [],
      isDataLoaded: false,
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user_group_list"]),
    baselineId() {
      return this.$store.getters.baselineId;
    },
    is_edit: {
      get() {
        return this.$store.getters.is_edit;
      },
      set(val) {
        this.$store.commit("SET_BASELINE_EDIT", val);
      },
    },
  },
  watch: {
    dataLoaded: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.isDataLoaded = true;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.get_baseline_data();
    this.get_allProjectMember();
  },
  methods: {
    ...mapActions(["setBaseLine"]),
    save_notice_person() {
      this.saving = true;
      let account = [];
      let group = [];
      if (this.baseline_notice) {
        this.baseline_notice.map((item, index) => {
          if (item.substr(-1) == 0) {
            account.push(item.substr(0, item.length - 1));
          } else {
            group.push(item.substr(0, item.length - 1));
          }
        });
      }
      change_baseinfo(this.get_pid(), this.baselineId, {
        description: this.baseline_desc,
        notification: {
          userList: account,
          userGroupList: group,
        },
      }).then((res) => {
        this.saving = false;
      });
    },
    get_allProjectMember() {
      get_allProjectMember(this.get_pid()).then((res) => {
        let my = {};
        let li = [];
        res.forEach((ele) => {
          let obj = {
            label: ele.nickname,
            value: ele.accountId,
            userAccountId: ele.accountId,
            avatar: ele.avatar,
            deleted: ele.deleted,
          };
          ele.accountId == this.$store.getters.userAccountId
            ? (my = obj)
            : li.push(obj);
        });
        let sort_user_list = [];
        sort_user_list.push(my);
        sort_user_list.push(...li);
        const group = Object.values(this.user_group_list);
        sort_user_list.map((item, index) => {
          this.groupandUser[0].options.push({
            value: item.value,
            label: item.label,
            avatar: item.avatar,
            colorid: item.userAccountId,
            nickname: item.label,
          });
        });
        group.map((item, index) => {
          this.groupandUser[1].options.push({
            value: item.groupId,
            label: item.groupName,
          });
        });
      });
    },
    get_show_person() {
      let r = [];
      this.baseline_notice_label &&
        this.baseline_notice_label.forEach((val) => {
          this.groupandUser[0].options.forEach((d) => {
            if (d.value == val) {
              r.push(d.label);
            }
          });
        });
      return r;
    },
    summaryOnmouserEnter() {
      this.timeOut = setTimeout(() => {
        if (this.activeNames.indexOf("1") == -1 && !this.is_edit) {
          this.activeNames.push("1");
        }
      }, 500);
    },
    summaryOnmouserLeave() {
      clearTimeout(this.timeOut);
      if (this.activeNames.indexOf("1") !== -1 && !this.is_edit) {
        this.activeNames.splice(this.activeNames.indexOf("1"), 1);
      }
    },
    limits_of_authority_disable,
    reload() {
      this.get_baseline_data();
      this.show_table = false;
      this.show_baseline_select = false;
      this.show_addnode = false;
      this.$nextTick(() => {
        this.show_table = true;
        this.show_baseline_select = true;
        this.show_addnode = true;
      });
    },
    get_baseline_data() {
      get_data(this.get_pid(), this.baselineId).then((res) => {
        this.baseline_info = res;
        this.baseline_info.createByName = this.matchUserNickname(
          this.baseline_info.createdBy,
          ""
        );
        this.baseline_name = res.name;
        this.baseline_desc = res.description;
        let notifyUsers = [];
        res.notifyUser.forEach((item) => {
          notifyUsers.push(item + "0");
        });
        this.baseline_notice = notifyUsers;
        this.baseline_notice_label = res.notifyUser;
        Object.keys(this.select_data).forEach((item) => {
          this.select_data[item] = res[item];
        });
        this.change_description();
      });
    },
    edit(val) {
      this.is_edit = val;
      if (val) {
        this.activeNames = ["1"];
      }
    },
    to_play() {
      this.$confirm(
        this.$t("baseline.topBar.beginBaseline.content"),
        this.$t("baseline.topBar.beginBaseline.title"),
        {
          showCancelButton: false,
          confirmButtonText: this.$t("btn.confirmBtn"),
          type: "warning",
        }
      ).then(() => {
        start_baseline(this.get_pid(), this.baselineId).then(() => {
          this.$message.success(this.$t("baseline.message.beginSuccess"));
          this.$store.commit("SET_BASELINE_LOCK", true);
          this.$store.commit("SET_BASELINE_STATUS", "ALREADY_STARTED");
        });
      });
    },
    change_baseline(baselineId, status) {
      this.is_edit = false;
      // 发送请求,重新渲染页面
      this.$store.commit("SET_BASELINE_ID", baselineId);
      this.$store.commit("SET_BASELINE_STATUS", status);
      this.reload();
      this.$router.push({
        name: "baseline",
        params: { cur_id: this.baselineId },
      });
    },
    exit_edit() {
      this.is_edit = false;
      this.activeNames = [];
      this.setBaseLine(this.get_pid());
      this.reload();
    },
    change_desc() {
      this.saving = true;

      this.change_description();

      change_baseinfo(this.get_pid(), this.baselineId, {
        description: this.baseline_desc,
      }).then(() => {
        this.saving = false;
      });
    },
    change_name() {
      this.saving = true;
      change_baseline_name(this.get_pid(), this.baselineId, {
        name: this.baseline_name,
      }).then(() => {
        this.saving = false;
      });
    },
    // 对描述进行换行替换处理
    change_description() {
      this.newDescription = this.baseline_desc.replace(/\n/g, "<br>");
    },
    handleChange() {},
  },
};
</script>

<style lang="scss" scoped>
.collapse_item {
  margin-bottom: 10px;
  margin-top: 17px;
  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
::v-deep .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-collapse-item__header {
  border-radius: 8px;
}

@keyframes myrotate {
  100% {
    transform: rotate(360deg);
  }
}
.baseline_before_start {
  &::-webkit-scrollbar {
    display: none;
  }
  // 顶部下拉框和按钮组
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__btns {
      display: flex;
      align-items: center;
      > * {
        margin-left: 10px;
      }
      .save_tip {
        font-size: 14px;
        color: rgba(166, 166, 166, 1);
        .svg_animation {
          animation: myrotate 1s linear infinite;
        }
      }
      ::v-deep.el-button {
        font-size: 16px;
        // padding: 6px 10px;
      }
    }
    &__baseline-select {
      display: flex;
      align-items: center;
      ::v-deep.el-input__inner {
        width: 260px;
        background-color: transparent;
      }
      .play {
        margin-left: 12px;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }
  // 版本信息区域
  .baseline-info {
    padding: 17px 23px 0px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
    color: rgba(128, 128, 128, 1);
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .create-time-and-person {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      text-align: left;
      .create_name {
        margin-right: 40%;
      }
    }
    .baseline-description {
      text-align: start;
      display: flex;
      word-break: break-all;
      .title {
        flex: 7%;
      }
      .title_show {
        width: 100px; /*no*/
      }
      .plan-text {
        max-height: 80px;
        overflow-y: auto;
        flex: 1;
      }
    }
    .title,
    .title_show {
      font-weight: 700;
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-bottom: 0 !important;
}

.startBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(40, 78, 163, 1);
  border-style: none;
  cursor: pointer;
  .startText {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    margin-left: 5px;
    letter-spacing: 1px;
    line-height: 5px;
  }
}
</style>
