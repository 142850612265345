<template>
  <div class="baseline_before_start_table" style="flex: 1; overflow: hidden">
    <el-pagination
      :page-size="pageSize"
      :pager-count="pageCount"
      :current-page.sync="pageCurrent"
      class="header-pagination"
      style="text-align: start"
      :background="false"
      small
      layout="prev, pager, next, total"
      :total="nodes_table.length"
    >
    </el-pagination>
    <div class="baseline-nodes" style="height: 93%">
      <el-table
        v-loading="loading"
        :data="current_nodes_table"
        :header-cell-style="{
          color: '#383838',
          fontWeight: 'blod',
          fontSize: '14px',
          backgroundColor: 'transparent',
        }"
        :cell-style="first_column_style"
        style="width: 100%"
        height="100%"
      >
        <el-table-column label="ID" min-width="120">
          <template slot-scope="scope">
            <div style="display: flex">
              <el-tooltip
                v-ak-tooltip-auto-show
                effect="light"
                placement="top-end"
                :content="scope.row.key"
              >
                <span
                  v-limits-of-authority="'NODE_DETAIL_READ'"
                  style="
                    cursor: pointer;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  @click="go_node_detial(scope.row.key)"
                  >{{ scope.row.key }}</span
                >
              </el-tooltip>
              <div style="display: inline-block">
                <div
                  v-limits-of-authority="'FILE_READ'"
                  style="display: inline-block"
                >
                  <i
                    style="
                      font-size: 12px;
                      margin-left: 16px;
                      cursor: pointer;
                      color: rgb(48, 100, 143);
                    "
                    class="iconfont icon-node_link"
                    @click="nodeLink(scope.row)"
                  ></i>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="fileTypeId"
          :label="$t('baseline.nodeTable.type')"
          show-overflow-tooltip
          :filters="typeFiltersList"
          :filter-method="filterHandler"
        >
          <template slot-scope="{ row }">
            <span>{{
              fileType[row.fileTypeId] ? fileType[row.fileTypeId].name : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="topic"
          :label="$t('baseline.nodeTable.name')"
          min-width="240"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <span style="cursor: pointer" @click="show_drawer(row)">{{
              row.topic
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('baseline.nodeTable.status')"
          min-width="200"
        >
          <template slot-scope="scope">
            <status-tag
              v-model="status[scope.row.status].name"
              :style_catogry="'plain'"
              :status_type="status[scope.row.status].statusCategory"
              :size="'small'"
            >
            </status-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="baselineNodeCreatedBy"
          :label="$t('baseline.nodeTable.operator')"
          min-width="160"
        >
        </el-table-column>
        <el-table-column
          prop="baselineNodeCreatedAt"
          :label="$t('baseline.nodeTable.addTime')"
          min-width="150"
          :formatter="formatCreateTime"
        >
        </el-table-column>
        <el-table-column v-if="is_edit" label="" width="50" align="right">
          <template slot-scope="scope">
            <i
              v-limits-of-authority="'NODE_EDIT'"
              class="iconfont icon-baseline-delete"
              style="cursor: pointer; color: #f56c6c"
              @click="handleDelete(scope.$index, scope.row.key)"
            >
            </i>
          </template> </el-table-column
      ></el-table>
    </div>
    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="current_node"
      :file_icon_name="file_icon_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />
  </div>
</template>

<script>
import { getFile } from "@/network/home/index.js";
import NodeDrawer from "@/components/nodeDrawer";
import { get_node_use_node_key } from "@/network/node/index.js";
import { inProgressBaselines } from "@/network/merge/index";
import { get_nodes, delete_nodes, get_file } from "@/network/baseline/index.js";
import StatusTag from "@/components/statusTag";
import { formatTime } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "BaselineBeforeStartTable",
  components: {
    StatusTag,
    NodeDrawer,
  },
  data() {
    return {
      // 节点列表
      nodes_table: [],
      loading: false,
      // 当前项目成员
      drawer: false,
      current_node: {},
      drawer_loading: false,
      file_icon_name: "",
      file_icon: "",
      typeFiltersList: [],
      pageSize: 20,
      pageCount: 5,
      pageCurrent: 1,
      current_nodes_table: [],
    };
  },
  computed: {
    ...mapGetters([
      "baselineId",
      "is_edit",
      "status",
      "fileType",
      "project_user_list",
    ]),
    nodes_list() {
      return this.nodes_table.map((item) => item.nodeKey);
    },
    showTestPlan() {
      if (this.current_node && this.current_node.fileTypeId && this.fileType) {
        return this.fileType[this.current_node.fileTypeId]
          ? this.fileType[this.current_node.fileTypeId].testCase
          : false;
      }
      return false;
    },
    members() {
      return Object.values(this.project_user_list);
    },
    membersShow() {
      return this.members.length ? true : false;
    },
  },
  watch: {
    pageCurrent: {
      handler(newv, oldv) {
        this.current_nodes_table = this.nodes_table.slice(
          (newv - 1) * this.pageSize,
          newv * this.pageSize
        );
      },
    },
    nodes_table: {
      handler(newv, oldv) {
        this.current_nodes_table = this.nodes_table.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      },
      immediate: true,
    },
  },
  created() {},

  mounted() {
    this.get_table_data();
  },

  methods: {
    // 文件类型过略
    filterHandler(value, row, column) {
      const property = column["property"];
      return this.fileType[row[property]].name === value;
    },
    get_table_data() {
      this.loading = true;
      this.typeFiltersList = [];
      const map = {};
      get_nodes(this.get_pid(), this.baselineId).then((res) => {
        this.loading = false;
        this.nodes_table = res.filter((item) => {
          item.baselineNodeCreatedBy = this.matchUserNickname(
            item.baselineNodeCreatedBy,
            ""
          );
          return !item.isDel;
        });
        res.forEach((item) => {
          const name = this.fileType[item.fileTypeId]
            ? this.fileType[item.fileTypeId].name
            : "";
          if (!map[name]) {
            const obj = {
              text: name,
              value: name,
            };
            this.typeFiltersList.push(obj);
            map[name] = true;
          }
        });
      });
    },
    set_title_style({ row, column, rowIndex, columnIndex }) {
      let style = " color: rgba(84, 85, 86, 1);font-size: 14px;padding:8px 0;";
      if (columnIndex == 0) style += "padding-left:10px";
      return style;
    },
    first_column_style({ columnIndex }) {
      let style = "padding:8px 0;";
      if (columnIndex == 0) {
        style += "color:rgba(255, 195, 0, 1);padding-left:10px";
      } else {
        style += "color:#636363; font-weight: 700";
      }
      return style;
    },
    handleDelete(index, nodeKey) {
      this.nodes_table.splice(index, 1);
      delete_nodes(this.get_pid(), this.baselineId, nodeKey).then(() => {
        this.$message.success(this.$t("baseline.message.deleteSuccess"));
      });
    },
    go_node_detial(nodeKey) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey,
        },
      });
      window.open(href, "_blank");
    },
    nodeLink(row) {
      get_file(this.get_pid(), row.fileId).then((result) => {
        getFile(this.get_pid(), result.key).then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          this.$store.commit("SET_FILE_ID", result.id);
          this.$store.commit("SET_FILE_KEY", result.key);
          this.$store.commit("SET_MINDMAP_NAME", result.name);
          this.$store.commit("SET_OPEN_STATE", "exist");
          this.$store.commit("SET_FILETYPE_ID", result.fileTypeId);
          //跳转到思维导图
          const { href } = this.$router.resolve({
            path: `/${this.get_pid()}/myMind/${result.key}`,
            query: {
              node_id: row.id, // 节点id
            },
          });
          window.open(href, "_blank");
        });
      });
    },
    formatCreateTime(row, column, cellValue, index) {
      return formatTime(cellValue);
    },
    before_close_drawer() {
      this.get_table_data();
      this.drawer = false;
    },
    show_drawer(node) {
      const jobAuthorities = this.$store.getters.jobAuthorities;
      if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
        this.$message({
          message: this.$t("board.message.error"),
          type: "warning",
        });
        return;
      }
      this.drawer_loading = true;
      this.$nextTick(() => {
        get_node_use_node_key(this.get_pid(), node.key).then((res) => {
          this.current_node = res;
          this.drawer = true;
        });
      });
    },
    update_description_content(description) {
      this.current_node.description = description;
    },
    update_current_node(e) {
      this.current_node = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.baseline_before_start_table {
  .baseline-nodes {
    padding: 8px 0;
    margin-top: 17px;
    border-radius: 8px;
    background-color: #fff;
    ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
      display: none;
    }
    ::v-deep .el-table::before {
      height: 0;
    }
    ::v-deep .el-table__row {
      td:nth-child(2) {
        > div {
          font-weight: bold;
        }
      }
    }
  }
}
::v-deep .el-pagination {
  .btn-prev {
    background: transparent !important;
  }
  .btn-next {
    background: transparent !important;
  }
  li {
    background: transparent !important;
  }
}
</style>
