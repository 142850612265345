<template>
  <div>
    <span>
      <span v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'">
        <el-button
          v-show="!has_baseline"
          :disabled="limits_of_authority_disable('DATUM_LINE_CREATE_EDIT_COPY')"
          type="primary"
          size="medium"
          @click="add_baseline_dialog = true"
          ><i class="iconfont icon-add"></i>&nbsp;&nbsp;{{
            $t("btn.newBtn")
          }}</el-button
        >
      </span>
    </span>

    <el-select
      v-show="baseline_options.length > 0"
      v-model="cur_baseline"
      size="small"
      @change="change_baseline"
      filterable
      popper-class="select_own"
    >
      <el-option
        :key="'add_new'"
        class="baseline-add-option"
        :value="cur_baseline"
      >
        <div
          v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'"
          style="width: 100%"
        >
          <div
            class="baseline-add"
            @click="add_baseline_dialog = true"
            style="display: flex; justify-content: center"
          >
            <i class="iconfont icon-add"></i>
          </div>
        </div>
      </el-option>
      <el-option
        v-for="(item, index) in baseline_options"
        :key="index"
        :label="`${item.name} (${item.status_ch})`"
        :value="item.baselineId"
      >
      </el-option>
    </el-select>
    <!--  添加基线弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible="computedVisible"
      width="25%"
      :title="$t('baseline.addBaselineForm.title')"
      custom-class="add_baseline"
      @close="handleCloseDialog"
    >
      <el-form
        ref="addBaselineForm"
        label-width="120px"
        :model="add_baseline_form"
        :rules="rules"
        label-position="left"
      >
        <el-form-item prop="name">
          <template slot="label">
            <span>{{ $t("baseline.addBaselineForm.name") }}</span>
          </template>
          <el-input
            v-model="add_baseline_form.name"
            :maxlength="255"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('baseline.addBaselineForm.desc')">
          <el-input v-model="add_baseline_form.description"> </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="cancelBtn">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          class="button-confirm"
          @click="add_baseline"
          >{{ $t("btn.newBtn") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { limits_of_authority_disable } from "@/utils/limits";
import {
  get_baseline_list,
  create_baseline,
} from "@/network/baseline/index.js";
import { mapActions } from "vuex";
import { sessionRead } from "@/utils/session";
export default {
  model: {
    event: "visibleChanged",
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    first_jump: {
      type: Boolean,
      default: () => false,
    },
    isDataLoaded: {
      type: Boolean,
      default: false,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cur_baseline: "",
      baseline_options: [],
      has_baseline: true,
      // 添加基线弹窗
      rules: {
        name: [
          {
            required: true,
            message: this.$t("baseline.message.NameCannotBeEmpty"),
            trigger: "blur",
          },
        ],
      },
      add_baseline_dialog: false,
      add_baseline_form: {
        name: "",
        description: "",
      },
    };
  },
  computed: {
    computedVisible() {
      return this.add_baseline_dialog || this.showDialog;
    },
  },
  mounted() {
    this.$watch(
      "isDataLoaded",
      (newVal) => {
        if (newVal) {
          this.get_options();
        }
      },
      { immediate: true }
    );
  },

  methods: {
    ...mapActions(["setBaseLine"]),
    limits_of_authority_disable,
    switch_status(val) {
      switch (val) {
        case "ALREADY_STARTED":
          return this.$t("canvas.testPlan.status.started");
        case "NOT_STARTED":
          return this.$t("canvas.testPlan.status.notStarted");
        case "COMPLETE":
          return this.$t("canvas.testPlan.status.completed");
      }
    },
    get_options() {
      get_baseline_list(this.get_pid()).then((res) => {
        const obj = {};
        const list = [
          ...(res.NOT_STARTED || []),
          ...(res.ALREADY_STARTED || []),
          ...(res.COMPLETE || []),
        ];
        list.forEach((item) => {
          obj[item.baselineId] = item;
        });
        this.$store.commit("setBaseLine", obj);
        if (JSON.stringify(res) == "{}") this.has_baseline = false;
        let result = [];
        let property = ["ALREADY_STARTED", "NOT_STARTED", "COMPLETE"];
        property.forEach((type) => {
          if (res[type]) {
            result = [...result, ...res[type]];
          }
        });
        this.baseline_options = result.map((ele) => {
          ele.status_ch = this.switch_status(ele.status);
          return ele;
        });
        if (this.$route.params.baselineId && this.first_jump) {
          this.$store.commit("SET_BASELINE_ID", this.$route.params.baselineId);
          this.$store.commit("SET_BASELINE_STATUS", this.$route.params.status);
        }
        this.cur_baseline =
          sessionRead("_baselineId") || this.baseline_options[0].baselineId;
      });
    },
    change_baseline(id) {
      let [obj] = this.baseline_options.filter((item) => item.baselineId == id);
      this.$emit("change_baseline", id, obj.status);
    },
    add_baseline() {
      this.$refs.addBaselineForm.validate((valid) => {
        if (valid) {
          create_baseline(this.get_pid(), this.add_baseline_form).then(
            (baselineId) => {
              this.add_baseline_dialog = false;
              this.setBaseLine(this.get_pid());
              this.$emit("change_baseline", baselineId, "NOT_STARTED");
            }
          );
        } else {
          return false;
        }
      });
    },
    handleCloseDialog() {
      this.add_baseline_dialog = false;
      this.$emit("dialog-closed", false);
    },
    cancelBtn() {
      this.add_baseline_dialog = false; // 关闭对话框
      this.handleCloseDialog(); // 手动触发关闭对话框的方法
    },
  },
};
</script>

<style lang="scss" scoped>
.baseline-add {
  width: 100%;
  color: rgb(48, 100, 143);
}
.data_none {
  text-align: center;
  color: #999;
  font-size: 14px;
}
::v-deep .add_baseline {
  text-align: left;
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
::v-deep.el-select-dropdown__item.baseline-add-option::after {
  content: "";
}
</style>

<style lang="scss">
.select_own .el-select-dropdown__list {
  padding: 0;
  margin: 0;
  li:nth-child(1) {
    background-color: rgba(77, 160, 255, 0.35);
    border-radius: 6px 6px 0px 0px;
  }
}
</style>
